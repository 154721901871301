import axios from 'axios';
import { SignupData, ManualSignupData } from '../types/auth.types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8003/shmup';

export const AuthService = {
  signup: async (data: SignupData) => {
    try {
      const response = await axios.post(`${API_URL}/users/internal`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  signupManual: async (data: ManualSignupData) => {
    try {
      const response = await axios.post(`${API_URL}/users/internal/manual`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}; 