import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert as MuiAlert,
  Tabs,
  Tab,
  MenuItem
} from '@mui/material';
import { AuthService } from '../../services/auth.service';
import { FormikHelpers } from 'formik';
import { KycType } from '../../types/kyc';

const quickValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  xFlowAccountId: Yup.string().required('XFlow Account ID is required'),
  accountNumber: Yup.string().nullable(),
  ifscCode: Yup.string().nullable(),
  bankAccountHolderName: Yup.string().nullable(),
  bankName: Yup.string().nullable(),
});

const quickInitialValues = {
  email: "",
  xFlowAccountId: "",
  accountNumber: "",
  ifscCode: "",
  bankAccountHolderName: "",
  bankName: "",
};

const manualValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  kycType: Yup.number().required('KYC Type is required'),
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  companyName: Yup.string().nullable(),
  phoneNumber: Yup.string().required('Phone number is required'),
  xFlowAccountId: Yup.string().required('XFlow Account ID is required'),
  accountNumber: Yup.string().required('Account number is required'),
  ifscCode: Yup.string().required('IFSC code is required'),
  bankAccountHolderName: Yup.string().required('Account holder name is required'),
  bankName: Yup.string().required('Bank name is required'),
  businessAddress: Yup.object().shape({
    country: Yup.string().required('Country is required'),
    streetAddressLine1: Yup.string().required('Street address is required'),
    streetAddressLine2: Yup.string().nullable(),
    postalCode: Yup.string().required('Postal code is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
  }).required(),
});

const manualInitialValues = {
  email: "",
  kycType: "" as unknown as number,
  firstName: "",
  lastName: "",
  companyName: "",
  phoneNumber: "",
  xFlowAccountId: "",
  accountNumber: "",
  ifscCode: "",
  bankAccountHolderName: "",
  bankName: "",
  businessAddress: {
    country: "India",
    streetAddressLine1: "",
    streetAddressLine2: "",
    postalCode: "",
    state: "",
    city: "",
  },
};

interface SnackbarState {
  open: boolean;
  message: string;
  severity: 'success' | 'error';
}

const Signup: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const showNotification = (message: string, severity: 'success' | 'error') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleQuickSubmit = async (values: typeof quickInitialValues, { setSubmitting }: FormikHelpers<typeof quickInitialValues>) => {
    try {
      await AuthService.signup(values);
      showNotification('Signup successful!', 'success');
    } catch (error: any) {
      const errorMessage = error.response?.data?.responseMessage || 'Signup failed. Please try again.';
      showNotification(errorMessage, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  const handleManualSubmit = async (values: typeof manualInitialValues, { setSubmitting }: FormikHelpers<typeof manualInitialValues>) => {
    try {
      await AuthService.signupManual(values);
      showNotification('Signup successful!', 'success');
    } catch (error: any) {
      const errorMessage = error.response?.data?.responseMessage || 'Signup failed. Please try again.';
      showNotification(errorMessage, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is Enter
    if (event.key === 'Enter') {
      // Prevent default behavior of Enter key
      event.preventDefault();
      
      // Get all input fields
      const inputs = document.querySelectorAll('input, select, textarea');
      
      // Convert to array and find current input's index
      const currentIndex = Array.from(inputs).indexOf(event.target as HTMLElement);
      
      // Move to next input if it exists
      if (currentIndex < inputs.length - 1) {
        (inputs[currentIndex + 1] as HTMLElement).focus();
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbar.severity}
          onClose={handleCloseSnackbar}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>

      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            width: '100%',
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Onboard our customer 
          </Typography>

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            sx={{ mb: 3 }}
          >
            <Tab label="Quick Signup" />
            <Tab label="Manual Signup" />
          </Tabs>

          {activeTab === 0 && (
            <Formik
              initialValues={quickInitialValues}
              validationSchema={quickValidationSchema}
              onSubmit={handleQuickSubmit}
            >
              {({ values, handleChange, handleBlur, isSubmitting }) => (
                <Form>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Personal Details
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="xFlowAccountId"
                        label="XFlow Account ID"
                        value={values.xFlowAccountId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                    </Box>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Bank Account Details
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <TextField
                        fullWidth
                        name="accountNumber"
                        label="Bank Account Number"
                        value={values.accountNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                      />
                      <TextField
                        fullWidth
                        name="ifscCode"
                        label="IFSC Code"
                        value={values.ifscCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                      />
                      <TextField
                        fullWidth
                        name="bankAccountHolderName"
                        label="Bank Account Holder Name"
                        value={values.bankAccountHolderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                      />
                      <TextField
                        fullWidth
                        name="bankName"
                        label="Bank Name"
                        value={values.bankName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                      />
                    </Box>
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={isSubmitting}
                    sx={{ mt: 3 }}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                  </Button>
                </Form>
              )}
            </Formik>
          )}

          {activeTab === 1 && (
            <Formik
              initialValues={manualInitialValues}
              validationSchema={manualValidationSchema}
              onSubmit={handleManualSubmit}
            >
              {({ errors, touched, handleChange, handleBlur, values, isSubmitting }) => (
                <Form>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Personal Details
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        select
                        fullWidth
                        name="kycType"
                        label="Business Type"
                        value={values.kycType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      >
                        {Object.entries(KycType)
                          .filter(([key]) => isNaN(Number(key))) // Filter out numeric keys
                          .map(([key, value]) => (
                            <MenuItem key={value} value={value}>
                              {key.charAt(0) + key.slice(1).toLowerCase().replace(/_/g, ' ')}
                            </MenuItem>
                          ))}
                      </TextField>
                      <TextField
                        fullWidth
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="companyName"
                        label="Company Name"
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="phoneNumber"
                        label="Phone Number (+91)"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="xFlowAccountId"
                        label="XFlow Account ID"
                        value={values.xFlowAccountId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      
                      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Address Details
                      </Typography>
                      <TextField
                        fullWidth
                        name="businessAddress.streetAddressLine1"
                        label="Street Address Line 1"
                        value={values.businessAddress.streetAddressLine1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="businessAddress.streetAddressLine2"
                        label="Street Address Line 2"
                        value={values.businessAddress.streetAddressLine2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                      />
                      <TextField
                        fullWidth
                        name="businessAddress.city"
                        label="City"
                        value={values.businessAddress.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="businessAddress.state"
                        label="State"
                        value={values.businessAddress.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="businessAddress.postalCode"
                        label="Postal Code"
                        value={values.businessAddress.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="businessAddress.country"
                        label="Country"
                        value={values.businessAddress.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        disabled
                      />
                    </Box>
                  </Box>

                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Bank Account Details
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <TextField
                        fullWidth
                        name="accountNumber"
                        label="Bank Account Number"
                        value={values.accountNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="ifscCode"
                        label="IFSC Code"
                        value={values.ifscCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="bankAccountHolderName"
                        label="Bank Account Holder Name"
                        value={values.bankAccountHolderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required
                      />
                      <TextField
                        fullWidth
                        name="bankName"
                        label="Bank Name"
                        value={values.bankName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        onKeyDown={handleEnterKey}
                        required={true}
                      />
                    </Box>
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={isSubmitting}
                    sx={{ mt: 3 }}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default Signup; 

